import {initCopyableContent} from "./initCopyableContent";
import {initDropdown} from "./initDropdown";
import {initFooterFrog} from "./initFooterFrog";
import {initMobileNavigation} from "./initMobileNavigation";
import {initHighDPI} from "./initHighDPI";
import {initMaps} from "./initMaps";
import {initScrollListeners} from "./initScrollListeners";
import {initSidebarTableOfContents} from "./initSidebarTableOfContents";
import {initTableOfContents} from "./initTableOfContents";
import {initOverlays} from "./initOverlays";
import {initValidationQuantity} from "./initValidationQuantity";
import {initAnimations} from "./initAnimations";
import {initLoginRegister} from "./initLoginRegister";
import {matchLocationPath} from "../sf-utils";

export function initGlobal() {
	// HEADER/FOOTER
	initFooterFrog();

	// DROPDOWN
	initDropdown();

	// NAVIGATION
	initMobileNavigation();

	// Overlays
	initOverlays();

	// MAPS
	initMaps();

	// SCROLL LISTENERS
	initScrollListeners();

	// TABLE OF CONTENTS (TOC)
	initSidebarTableOfContents();
	initTableOfContents();

	// LOGIN/REGISTER:
	if (
		matchLocationPath("/login/") || // if login page...
		matchLocationPath("/checkout/") || // ...(login exists on checkout page too...
		matchLocationPath("/my-account/*", false) // ...and on my-account pageS)...
	) {
		initLoginRegister(); // ...init login/register
	}

	// VALIDATION
	initValidationQuantity();

	// OTHER
	initCopyableContent(".copy-content");
	initHighDPI();
	initAnimations();

	// SF TODO: is this needed?!? is it to do with FAQ?
	// adds active class to menu items when listed in the URL as a hash
	const id = window.location.hash;
	$(id).addClass("active");
}
